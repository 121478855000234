import React from "react";
import ParticlesBg from "particles-bg";
import Typing from "react-typing-animation";

import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
import banner from "../assets/images/2.png";
import Header2 from "../Components/Header2";

function Home(props) {
  return (
    <div className='page-wrapper'>
      <div className='home-bg'>
        <div className='container'>
          {/* Header */}
          <Header></Header>
          <Header2></Header2>
          <div className='leaderboard'>
            <div className='align-center'>
              <h1>
                <Typing loop={true}>
                  <div>
                    {/* <span>Hey</span>
                    <Typing.Reset count={2} delay={500} /> */}
                    <span>Together we can save lives!!</span>
                    <Typing.Reset count={1} delay={500} />
                  </div>
                  <Typing.Reset count={1} delay={500} />
                </Typing>
              </h1>

              <h4>
                XR Central (XRC) and ICanCaRe brings to you a digital platform,
                first of its kind, that will encourage, influence and educate
                the youth and help win against cancer through a plethora of fun,
                interactive, immersive and engaging games.
              </h4>
              {/* <h3></h3> */}
              {/* <h1>CANCER</h1> */}
            </div>

            {props.parentState.loggedIn ? null : (
              <div className='home-authentication'>
                <Link to='/register' className='cmn-register-btn'>
                  Register
                </Link>
                <Link to='/login' className='cmn-register-btn'>
                  Login
                </Link>
              </div>
            )}

            <div className='justify-content-center'>
              <div className='home-content-wrapper'>
                <img src={banner} alt='home banner icancare'></img>
              </div>
            </div>
          </div>

          {/* Footer */}
          <Footer></Footer>
        </div>
      </div>
      <ParticlesBg num={1} type='color' bg={true} />
    </div>
  );
}

export default Home;
