const PhoneRegex = RegExp(/^\d{10}$/);

const isFieldEmpty = (field) => {
  return field.length <= 0;
};

const isValidPhone = (phone) => {
  return PhoneRegex.test(phone);
};

const validatePhone = (phone) => {
  if (isFieldEmpty(phone)) {
    return "Field is required";
  } else if (!isValidPhone(phone)) {
    return "Please enter valid phone number";
  }
};

module.exports = {
  validatePhone,
};
