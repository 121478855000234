import React, { Component } from "react";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import PhoneCode from "react-phone-code";
import ParticlesBg from "particles-bg";
import { nanoid } from "nanoid";

import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Header2 from "../Components/Header2";
import { db } from "../firebase";
import validateRegister from "../validations/register";
import { Link } from "react-router-dom";

export class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userId: "",
      firstName: "",
      lastName: "",
      countryCode: "",
      email: "",
      phone: "",
      city: "",
      signUpForEmail: false,

      registerSuccess: false,
      userAlreadyExist: "",
      validationError: {},
    };
  }

  componentDidMount() {
    const userId = nanoid(32);
    this.setState({
      userId: userId,
    });
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSignUpForEmail = () => {
    this.setState({
      signUpForEmail: !this.state.signUpForEmail,
    });
  };

  userExistInDatabase = async (phone, countryCode) => {
    var userInDb = false;
    const user = await db
      .collection("users")
      .where("phone", "==", phone)
      .where("countryCode", "==", countryCode)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          if (doc.id) {
            userInDb = true;
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
    return userInDb;
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    // clear if already present
    this.setState({
      userAlreadyExist: "",
    });

    const validationPayload = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      phone: this.state.phone,
      city: this.state.city,
    };

    const validationError = validateRegister(validationPayload);
    if (Object.keys(validationError).length > 0) {
      this.setState({
        validationError: validationError,
      });
      return null;
    }

    const userPayload = {
      userId: this.state.userId,
      firstName: this.state.firstName.toLowerCase(),
      lastName: this.state.lastName.toLowerCase(),
      email: this.state.email.toLowerCase(),
      countryCode: this.state.countryCode,
      phone: this.state.phone,
      city: this.state.city.toLowerCase(),
      OTPverified: false,
      signUpForEmail: this.state.signUpForEmail,
      createdAt: new Date(),
    };

    if (
      await this.userExistInDatabase(this.state.phone, this.state.countryCode)
    ) {
      this.setState({
        userAlreadyExist:
          "Number you entered with country code is already registered. Please login",
      });
      return null;
    }

    // SAVE USER
    db.collection("users")
      .doc(this.state.userId)
      .set(userPayload)
      .then(() => {
        this.props.handleRegisterSuccess(this.state.email.toLowerCase());
        this.setState({ registerSuccess: true });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    return (
      <div className='page-wrapper'>
        <div className='home-bg'>
          {/* Redirrects  */}
          {this.state.registerSuccess ? (
            <Redirect to='/login'></Redirect>
          ) : null}

          <div className='container'>
            <Header></Header>
            <Header2></Header2>

            <div className='row justify-content-center'>
              <div className='col-md-7'>
                <div className='cmn-container'>
                  <h2>Register Here</h2>

                  <form onSubmit={this.handleSubmit}>
                    <div className='form-group row cmn-form-field'>
                      <label
                        htmlFor='firstname'
                        className='col-sm-4 col-form-label'>
                        <span>*</span>First Name
                      </label>
                      <div className='col-sm-8'>
                        <input
                          type='text'
                          className='form-control'
                          id='firstname'
                          name='firstName'
                          value={this.state.firstName}
                          onChange={this.handleInputChange}
                        />
                        {/* Validation Error */}
                        {this.state.validationError.firstName ? (
                          <p className='small-txt' style={{ color: "red" }}>
                            {this.state.validationError.firstName}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className='form-group row cmn-form-field'>
                      <label
                        htmlFor='lastname'
                        className='col-sm-4 col-form-label'>
                        <span>*</span>Last Name
                      </label>
                      <div className='col-sm-8'>
                        <input
                          type='text'
                          className='form-control'
                          id='lastname'
                          name='lastName'
                          value={this.state.lastName}
                          onChange={this.handleInputChange}
                        />
                        {/* Validation Error */}
                        {this.state.validationError.lastName ? (
                          <p className='small-txt' style={{ color: "red" }}>
                            {this.state.validationError.lastName}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className='form-group row cmn-form-field'>
                      <label
                        htmlFor='email'
                        className='col-sm-4 col-form-label'>
                        <span></span>Email ID
                      </label>
                      <div className='col-sm-8'>
                        <input
                          type='email'
                          className='form-control'
                          id='email'
                          name='email'
                          value={this.state.email}
                          onChange={this.handleInputChange}
                        />
                        {/* Validation Error */}
                        {this.state.validationError.email ? (
                          <p className='small-txt' style={{ color: "red" }}>
                            {this.state.validationError.email}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className='form-group row cmn-form-field'>
                      <label htmlFor='city' className='col-sm-4 col-form-label'>
                        <span>*</span>Country Calling Code
                      </label>
                      <div className='col-sm-6'>
                        <PhoneCode
                          onSelect={(code) =>
                            this.setState({ countryCode: code })
                          } // required
                          showFirst={["IN"]}
                          defaultValue='select county'
                          id='countryCode'
                          name='countryCode'
                          className='custom-select'
                          optionClassName=''
                        />
                      </div>
                    </div>

                    <div className='form-group row cmn-form-field'>
                      <label
                        htmlFor='mobile'
                        className='col-sm-4 col-form-label'>
                        <span>*</span>Mobile
                      </label>
                      <div className='col-sm-8'>
                        <input
                          type='tel'
                          className='form-control'
                          id='mobile'
                          name='phone'
                          value={this.state.phone}
                          onChange={this.handleInputChange}
                        />
                        {/* Validation Error */}
                        {this.state.validationError.phone ? (
                          <p className='small-txt' style={{ color: "red" }}>
                            {this.state.validationError.phone}
                          </p>
                        ) : null}
                        <p className='small-txt'>
                          *This phone number will be used for all further
                          communication and as username for login
                        </p>
                      </div>
                    </div>

                    <div className='form-group row cmn-form-field'>
                      <label
                        htmlFor='lastname'
                        className='col-sm-4 col-form-label'>
                        <span>*</span>City
                      </label>
                      <div className='col-sm-8'>
                        <input
                          type='text'
                          className='form-control'
                          id='lastname'
                          name='city'
                          value={this.state.city}
                          onChange={this.handleInputChange}
                        />
                        {/* Validation Error */}
                        {this.state.validationError.city ? (
                          <p className='small-txt' style={{ color: "red" }}>
                            {this.state.validationError.city}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className='my-4 mx-4'>
                      <div className='form-check'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='yes-email'
                          onClick={this.handleSignUpForEmail}
                        />
                        <label
                          className='form-check-label chkbox-lbl'
                          for='yes-email'>
                          Sign up for Email &amp; WhatsApp updates
                        </label>
                      </div>
                    </div>

                    {/* Error here */}
                    <div className='align-center'>
                      {this.state.userAlreadyExist ? (
                        <p className='small-txt' style={{ color: "red" }}>
                          {this.state.userAlreadyExist}
                        </p>
                      ) : null}
                    </div>

                    <div className='align-center' style={{ marginTop: "30px" }}>
                      {this.state.userAlreadyExist ? (
                        <Link
                          style={{
                            color: "purple",
                            textDecorationLine: "underline",
                          }}
                          to='/LOGIN'>
                          LOGIN
                        </Link>
                      ) : null}
                    </div>

                    <input
                      type='submit'
                      className='cmn-register-btn text-uppercase'
                      value='Register Now'
                    />
                  </form>
                </div>
              </div>
            </div>
            <Footer></Footer>
          </div>
        </div>
        <ParticlesBg num={2} type='color' bg={true} />
      </div>
    );
  }
}

export default Register;
// const docname = this.state.countryCode + this.state.phone.toLowerCase();
// const ref = db.collection("users").doc(docname).get();
// if ((await ref).exists) {
//   this.setState({
//     userAlreadyExist:
//       "Number you entered with country code is already registered. Please login",
//   });
//   return null;
// }
