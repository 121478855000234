import React, { Component } from "react";
import PhoneCode from "react-phone-code";
import OtpInput from "react-otp-input";
import ParticlesBg from "particles-bg";

import { firebase, db } from "../firebase";
import Auth from "../Auth";
import { Link, Redirect } from "react-router-dom/cjs/react-router-dom.min";

import Header from "../Components/Header";
import Header2 from "../Components/Header2";
import Footer from "../Components/Footer";
import "../assets/css/login.css";
import Validation from "../validations/login";

// FIXME one timeout rejection error
export class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      countryCode: "",
      phone: "",
      OTP: 0,

      userId: "",
      email: "",
      firstName: "",
      successPhoneEnter: false,

      error: "",
      phoneNotRegisteredError: "",
      loggedInSuccess: false,
      userNotExist: false,
      validationError: "",
      validOTP: false,
    };
  }

  handleInput = (e) => {
    this.setState({
      phone: e.target.value,
    });
  };

  handleOTPInput = (val) => {
    this.setState({ validOTP: false, OTP: val });
    if (val.toString().length === 6) {
      this.setState({
        validOTP: true,
      });
    }
  };

  userExistInDatabase = async (phone, countryCode) => {
    var userInDb;
    const user = await db
      .collection("users")
      .where("phone", "==", phone)
      .where("countryCode", "==", countryCode)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          if (doc.id) {
            userInDb = doc.data();
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
    return userInDb;
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    // Clear previous error
    this.setState({ userNotExist: false });

    if (Validation.validatePhone(this.state.phone)) {
      this.setState({
        validationError: Validation.validatePhone(this.state.phone),
      });
      return null;
    }

    const userFromDb = await this.userExistInDatabase(
      this.state.phone,
      this.state.countryCode
    );
    if (!userFromDb) {
      this.setState({ userNotExist: true });
      return null;
    }

    this.setState({
      userId: userFromDb.userId,
      email: userFromDb.email,
      firstName: userFromDb.firstName,
    });

    try {
      let recaptcha = new firebase.auth.RecaptchaVerifier("recaptcha");
      const number = this.state.countryCode + this.state.phone;
      firebase
        .auth()
        .signInWithPhoneNumber(number, recaptcha)
        .then((e) => {
          this.setState({ successPhoneEnter: true });
          window.confirmationResult = e;
        });
    } catch (error) {
      console.log(error, "recaptcha");
    }

    return null;
  };

  handleOTPSubmit = (e) => {
    e.preventDefault();
    try {
      window.confirmationResult
        .confirm(this.state.OTP)
        .then(async (result) => {
          // Update database
          db.collection("users")
            .doc(this.state.userId)
            .update({ OTPverified: true })
            .then(() => {
              // Save to Local storage
              Auth.setToLocal({
                _email: this.state.email,
                _uid: this.state.userId,
                _phone: this.state.phone,
                _firstName: this.state.firstName,
              });
              this.props.handleLoginSuccess({
                email: this.state.email,
                firstName: this.state.firstName,
                phone: this.state.phone,
                uid: this.state.userId,
              });
              // window.confirmationResult.clear();
              // window.confirmationResult.render().then(function (widgetId) {
              //   grecaptcha.reset(widgetId);
              // });

              this.setState({ loggedInSuccess: true });
            })
            .catch((error) => {
              console.log("after confirm while updating database", error);
            });
        })
        .catch((error) => {
          console.log(error, "on otp submit");
        });
    } catch (error) {
      console.log("recaptcha2", error);
    }
  };

  render() {
    var mainContent;
    if (this.state.successPhoneEnter) {
      mainContent = (
        <div class='row justify-content-center'>
          <div class='col-md-7'>
            <div class='cmn-container' style={{ textAlign: "center" }}>
              <h2>Please Verify OTP</h2>
              <span>OTP has been sent to your number</span>
              <form onSubmit={this.handleOTPSubmit}>
                <div className='otp-input'>
                  <OtpInput
                    value={this.state.OTP}
                    onChange={(val) => {
                      this.handleOTPInput(val);
                    }}
                    numInputs={6}
                    separator={<span> &nbsp;.&nbsp;</span>}
                  />
                </div>

                <button
                  type='submit'
                  class='cmn-register-btn text-uppercase'
                  disabled={this.state.validOTP ? false : true}>
                  VERIFY
                </button>
              </form>
            </div>
          </div>
        </div>
      );
    } else {
      mainContent = (
        <div>
          <div className='align-center' style={{ padding: "40px 0px" }}>
            <h2 style={{ marginBottom: "40px" }}>Login to play</h2>
            <p>
              Sign in using your registered mobile number to play ICanCaRe Games
            </p>
          </div>
          <form onSubmit={this.handleSubmit}>
            <div className='align-center'>
              {this.state.userNotExist ? (
                <p className='small-txt' style={{ color: "red" }}>
                  OOPS! Entered number is not registered yet. Please register
                </p>
              ) : null}
            </div>

            <div className='login-wrapper'>
              <div
                className='form-group row cmn-form-field'
                style={{ width: "10%" }}>
                <PhoneCode
                  onSelect={(code) => this.setState({ countryCode: code })} // required
                  showFirst={["IN"]}
                  defaultValue='select county'
                  id='countryCode'
                  name='countryCode'
                  className='custom-select'
                  optionClassName=''
                />
              </div>
              <div className='form-group row cmn-form-field login-phone-field'>
                <input
                  type='text'
                  className='form-control'
                  placeholder='98xxxxxxxx'
                  id='firstname'
                  name='firstName'
                  value={this.state.phone}
                  onChange={this.handleInput}
                />
                {/* Validation Error */}
                {this.state.validationError ? (
                  <p className='small-txt' style={{ color: "red" }}>
                    {this.state.validationError}
                  </p>
                ) : null}
              </div>
            </div>
            {this.state.loggedInSuccess ? null : (
              <div
                id='recaptcha'
                style={{ display: "flex", justifyContent: "center" }}></div>
            )}
            {/* If number not verified */}
            <div className='align-center'>
              {this.state.userNotExist ? (
                <Link
                  style={{
                    color: "purple",
                    textDecorationLine: "underline",
                  }}
                  to='/register'>
                  REGISTER
                </Link>
              ) : null}
            </div>

            <button type='submit' className='cmn-register-btn text-uppercase'>
              Send Verification Code
            </button>
          </form>
        </div>
      );
    }
    return (
      <div className='page-wrapper' sty>
        <div className='home-bg'>
          {this.state.loggedInSuccess
            ? (() =>
                (window.location.href = window.location.origin + "/games"))()
            : null}
          <div className='container'>
            {/* Header */}
            <Header></Header>
            <Header2></Header2>

            {mainContent}

            {/* Footer */}
            <Footer></Footer>
          </div>
        </div>
        <ParticlesBg type='color' bg={true} />
      </div>
    );
  }
}

export default Home;
