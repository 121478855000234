// Store in cookies
const getFromLocal = () => {
  if (localStorage.getItem("icancare") === null) {
    return null;
  }
  return JSON.parse(localStorage.getItem("icancare"));
};

const setToLocal = (json) => {
  const value = JSON.stringify(json);
  localStorage.setItem("icancare", value);
};

const Logout = () => {
  localStorage.removeItem("icancare");
};

module.exports = {
  getFromLocal,
  setToLocal,
  Logout,
};
