import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Auth from "../Auth";

import Logo from "../assets/images/icancare.png";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

function Header(props) {
  const [LoggedInUser, setLoggedInUser] = useState(null);
  const [toggleLogout, setToggleLogout] = useState(false);

  useEffect(() => {
    const userFromLocal = Auth.getFromLocal();
    if (userFromLocal) setLoggedInUser(userFromLocal);
  }, []);

  const handleLogout = () => {
    Auth.Logout();
    window.location.href = "/";
  };

  if (LoggedInUser) {
    return (
      <div>
        <header className='header'>
          {/* If logged In */}
          <div className='row'>
            <div className='col-6'>
              <Link to='/' className='logo'>
                <img src={Logo} alt='logo' />
              </Link>
            </div>
            <div className='col-6 user-detail-wrapper'>
              <div className='user-detail'>
                <span>
                  Welcome&nbsp;
                  <span className='firstname'>{LoggedInUser._firstName}</span>
                </span>
                <i
                  onClick={() => setToggleLogout(!toggleLogout)}
                  class='fas fa-user menu-btn-trigger'></i>
                {toggleLogout ? (
                  <div className='custom-dropdown'>
                    <span onClick={handleLogout}>
                      <i class='fas fa-power-off'></i>Logout
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </header>
      </div>
    );
  } else {
    return (
      <div>
        <header className='header'>
          <Link to='/' className='logo'>
            <img src={Logo} alt='logo' />
          </Link>
        </header>
      </div>
    );
  }
}

export default Header;
