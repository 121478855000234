import React from "react";
import { Link } from "react-router-dom";
import "../assets/css/menu.css";

function Header2() {
  return (
    <div className="main-menu">
      <div className="main-menu-item ">
        <Link to="/" className="text-uppercase">
          <i class="fas fa-home"></i>
        </Link>
      </div>

      <div className="main-menu-item ">
        <Link to="/games" className="text-uppercase">
          <i class="fas fa-gamepad"></i>
          <span className="menu-text">GAMES</span>
        </Link>
      </div>
      <div className="main-menu-item">
        <Link to="/leaderboard" className="text-uppercase">
          <i class="fas fa-chart-bar"></i>
          <span className="menu-text">LEADERBOARD</span>
        </Link>
      </div>
      <div className="main-menu-item">
        <Link to="/contact" className="text-uppercase">
          <i class="fas fa-map-marked-alt"></i>
          <span className="menu-text">CONTACT</span>
        </Link>
      </div>
    </div>
  );
}

export default Header2;
