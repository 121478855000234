import firebase from "firebase";

var config = {
  apiKey: "AIzaSyAzUqF2grQ1Etm6EZbjwwNgJa86488QR_0",
  authDomain: "icancare-demo.firebaseapp.com",
  databaseURL: "https://icancare-demo.firebaseio.com",
  projectId: "icancare-demo",
  storageBucket: "icancare-demo.appspot.com",
  messagingSenderId: "469809572559",
  appId: "1:469809572559:web:5ffdac3cbcbf390cefe5ba",
};

firebase.initializeApp(config);
// export default firebase;

const db = firebase.firestore();
// const firebaseAuth = firebase.auth();

export { db, firebase };
