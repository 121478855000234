import React from "react";
// import { Link } from "react-router-dom/cjs/react-router-dom.min";
import XR from "../assets/images/XR.png";

function Footer() {
  return (
    <div>
      <div className="mt-5 text-center" style={{ paddingBottom: "120px" }}>
        <h5>Powered by&nbsp;</h5>
        <a href="http://xrcentral.tech/" target="_blank">
          <img width="60px" src={XR} alt="XR Central" />
        </a>
      </div>
    </div>
  );
}

export default Footer;
