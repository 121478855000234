import React, { Component } from "react";
import ParticlesBg from "particles-bg";
import Typing from "react-typing-animation";

import Header from "../Components/Header";
import Header2 from "../Components/Header2";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
import Game1 from "../assets/images/runnergame.png";
import Game2 from "../assets/images/flipgame.png";
import ARfilter from "../assets/images/Icon3.png";

export class Games extends Component {
  // game1ClickHandler = () => {
  //   window.location.origin;
  // };

  render() {
    var memoryFlip;
    var runnerGame;

    if (this.props.parentState.loggedIn) {
      memoryFlip =
        window.location.origin +
        "/memoryflip/?uid=" +
        this.props.parentState.uid;
      runnerGame =
        window.location.origin +
        "/smokerunner/?uid=" +
        this.props.parentState.uid;
    } else {
      memoryFlip = window.location.origin;
      runnerGame = window.location.origin;
    }

    return (
      <div className='page-wrapper'>
        <div className='home-bg'>
          <div className='container'>
            {/* Header */}
            <Header></Header>
            <Header2></Header2>

            <div className='row justify-content-center'>
              <div className='col-md-12'>
                <div className='cmn-container'>
                  <div className='align-center'>
                    <h1>
                      <Typing loop={true}>
                        <div>
                          <span>Discover</span>
                          <Typing.Reset count={4} delay={100} />
                          <span>Conquer</span>
                          <Typing.Reset count={3} delay={100} />
                          <span>Inspire</span>
                          <Typing.Reset count={2} delay={100} />
                          <span>Achieve</span>
                          <Typing.Reset count={1} delay={100} />
                        </div>
                        <Typing.Reset count={1} delay={500} />
                      </Typing>
                    </h1>
                    <h5>
                      Get ready to play, win and challenge your friends through
                      a range of fun filled interactive games. Earn points and
                      move up the leaderboard and most importantly earn the
                      bragging rights.
                    </h5>

                    <div className='row'>
                      <div className='col-sm-6 my-4 home-games'>
                        <h3>PLAY RUNNER-GAME </h3>
                        <a href={runnerGame}>
                          <img
                            src={Game1}
                            alt='Infinite runner game icancare'
                          />
                        </a>
                      </div>
                      <div className='col-sm-6 my-4 home-games'>
                        <h3>PLAY MEMORY-FLIP</h3>
                        <a href={memoryFlip}>
                          <img src={Game2} alt='turn the tiles game icancare' />
                        </a>
                      </div>
                    </div>
                    <div className='row justify-content-center home-games'>
                      <div className='col-sm-4 my-4 ar-filter-icon'>
                        <h3>TRY AR-FILTER</h3>
                        <div>
                          <img
                            src={ARfilter}
                            alt='AR filter for facebook and instagram icancare'
                          />
                        </div>
                        <div className='ar-filter-social'>
                          <a
                            href='https://www.facebook.com/fbcameraeffects/tryit/1264873667206635/'
                            target='_blank'>
                            <i class='fab fa-facebook-square facebook'></i>
                          </a>
                          <a
                            href='https://www.instagram.com/ar/1264873667206635/'
                            target='_blank'>
                            <i class='fab fa-instagram-square instagram'></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Check for user logged in */}
            {this.props.parentState.loggedIn ? null : (
              <Link to='/register' className='cmn-register-btn'>
                Register Now
              </Link>
            )}
            {/* Footer */}
            <Footer></Footer>
          </div>
        </div>
        <ParticlesBg type='color' bg={true} />
      </div>
    );
  }
}

export default Games;
