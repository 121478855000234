const EmailRegex = RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);
const PhoneRegex = RegExp(/^\d{10}$/);

function isFieldEmpty(field) {
  return field.length > 0;
}

function isPhoneValid(phone) {
  return PhoneRegex.test(phone);
}

const validateRegister = (user) => {
  var validationError = {};

  if (!isFieldEmpty(user.firstName)) {
    validationError.firstName = "Field is required";
  }

  if (!isFieldEmpty(user.lastName)) {
    validationError.lastName = "Field is required";
  }

  if (!isFieldEmpty(user.phone)) {
    validationError.phone = "Field is required";
  } else if (!isPhoneValid(user.phone)) {
    validationError.phone = "Please enter valid phone number";
  }

  if (!isFieldEmpty(user.city)) {
    validationError.city = "Field is required";
  }

  return validationError;
};

export default validateRegister;
