import React, { useEffect, useState } from "react";
import ParticlesBg from "particles-bg";
import { db } from "../firebase";

// import { Link } from "react-router-dom";
import Header from "../Components/Header";
import Header2 from "../Components/Header2";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";

function LeaderBoard(props) {
  const [userScore, setUserScore] = useState(0);
  const [topScores, setTopScores] = useState([]);

  useEffect(async () => {
    if (props.parentState.uid) {
      var topScores = [];
      await db
        .collection("users")
        .doc(props.parentState.uid)
        .get()
        .then((doc) => {
          if (!doc.exists) return null;
          setUserScore(doc.data().Totalscore);
        });
      await db
        .collection("users")
        .orderBy("Totalscore", "desc")
        .limit(20)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            const score = {
              firstName: doc.data().firstName,
              lastName: doc.data().lastName,
              score: doc.data().Totalscore,
            };
            topScores.push(score);
          });
          setTopScores(topScores);
        });
    }
  }, [props.parentState]);
  return (
    <div className='page-wrapper'>
      <div className='home2-bg'>
        <div className='container'>
          {/* Header */}
          <Header></Header>
          <Header2></Header2>

          <div className='leaderboard' style={{ minHeight: "60vh" }}>
            <div className='align-center'>
              <h1>Brag all you want!</h1>
              <h5>
                Compete with your friends and earn the bragging rights. All
                games are linked to a unique Leaderboard to ignite a competitive
                spirit within you. Track your performance and achievements
                through your personal Leaderboard. Let’s gear up and bring out
                the gamer in you. All the best!!
              </h5>
            </div>
            {props.parentState.loggedIn ? (
              <div>
                <div className='my-score align-center'>
                  <h1>Your Score</h1>
                  <h4>
                    <i class='fas fa-chess-queen' style={{ color: "gold" }}></i>
                    <span key={userScore}>{userScore}</span> points
                  </h4>
                </div>
                <div className='align-center'>
                  <h2>TOP SCORERS</h2>
                </div>
                {topScores
                  .sort((a, b) => (a.score < b.score ? 1 : -1))
                  .map((item, index) => {
                    return (
                      <div className='top-score-item' key='index'>
                        <div className='row'>
                          <div className='col-1 align-center'>
                            <span className='rank'>{index + 1}</span>
                          </div>
                          <div className='col-7'>
                            <span className='name'>{item.firstName}</span>
                            &nbsp;<span className='name'>{item.lastName}</span>
                          </div>

                          <div className='col-4 align-center'>
                            <i class='fas fa-chess-queen'></i>
                            <span>{item.score}</span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {/* end */}
              </div>
            ) : null}
          </div>

          {/* Check for user logged in */}
          {props.parentState.loggedIn ? null : (
            <Link to='/register' className='cmn-register-btn'>
              Register Now
            </Link>
          )}
          {/* Footer */}
          <Footer></Footer>
        </div>
      </div>{" "}
      <ParticlesBg type='polygon' bg={true} />
    </div>
  );
}

export default LeaderBoard;
