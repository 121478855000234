import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "./App.css";
import "./assets/css/style.css";

import Home from "./Pages/Home";
import Register from "./Pages/Register";
import Contact from "./Pages/Contact";
import LeaderBoard from "./Pages/LeaderBoard";
import Login from "./Pages/Login";
import About from "./Pages/Games";
import "./assets/css/fonts.css";

import Auth from "./Auth";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // After login saved to state
      userEmail: "",
      userFirstName: "",
      userPhone: "",
      uid: "",
      loggedIn: false,

      loggedInSuccessNotification: false,
      registerSuccessNotification: false,
      logoutSuccessNotification: false,
    };
  }

  componentDidMount() {
    const _value = Auth.getFromLocal();
    // console.log(_value);
    if (_value) {
      this.setState({
        uid: _value._uid,
        userEmail: _value._email,
        userFirstName: _value._firstName,
        userPhone: _value._phone,
        loggedIn: true,
      });
    } else {
      this.setState({
        uid: "",
        userEmail: "",
        userFirstName: "",
        userPhone: "",
        loggedIn: false,
      });
    }
  }

  // On success logged in it is called for notification and all
  handleLoginSuccess = ({ email, firstName, phone, uid }) => {
    this.setState({
      loggedInSuccessNotification: true,
      userEmail: email,
      userFirstName: firstName,
      userPhone: phone,
      uid: uid,
    });

    setTimeout(() => {
      this.setState({ loggedInSuccessNotification: false });
    }, 2000);
  };

  // on success register it is called for notification and all
  handleRegisterSuccess = (email) => {
    this.setState({
      registerSuccessNotification: true,
    });

    setTimeout(() => {
      this.setState({ registerSuccessNotification: false });
    }, 3000);
  };

  handleLogoutSuccess = () => {
    this.setState({
      logoutSuccessNotification: true,
    });

    setTimeout(() => {
      this.setState({ logoutSuccessNotification: false });
    }, 3000);
  };

  render() {
    return (
      <div>
        <Router>
          <Switch>
            <Route
              path='/'
              exact
              render={() => <Home parentState={this.state} />}
            />

            <Route
              path='/register'
              render={() => (
                <Register
                  parentState={this.state}
                  handleRegisterSuccess={this.handleRegisterSuccess}
                />
              )}
            />

            <Route
              path='/contact'
              render={() => <Contact parentState={this.state} />}
            />

            <Route
              path='/games'
              render={() => <About parentState={this.state} />}
            />

            <Route
              path='/login'
              render={() => (
                <Login handleLoginSuccess={this.handleLoginSuccess} />
              )}
            />

            <Route
              path='/leaderboard'
              render={() => <LeaderBoard parentState={this.state} />}
            />
          </Switch>
        </Router>

        {/* Notifications  on register and login */}
        <div
          className={
            this.state.registerSuccessNotification
              ? "success-animation"
              : "success-animation-none"
          }>
          <span>You have successfully registered&nbsp;</span>
        </div>
        {/* Login notification */}
        <div
          className={
            this.state.loggedInSuccessNotification
              ? "success-animation"
              : "success-animation-none"
          }>
          <span>Logged in successfully &nbsp;</span>
        </div>
        {/* Logout notification */}
        <div
          className={
            this.state.logoutSuccessNotification
              ? "success-animation"
              : "success-animation-none"
          }>
          <span>Logged out successfully &nbsp;</span>
        </div>
      </div>
    );
  }
}

export default App;
