import React, { Component } from "react";
import ParticlesBg from "particles-bg";

import { Link } from "react-router-dom";
import Header from "../Components/Header";
import Header2 from "../Components/Header2";
import Footer from "../Components/Footer";

export class Contact extends Component {
  render() {
    return (
      <div className='page-wrapper'>
        <div className='home-bg'>
          <div className='container'>
            {/* Header */}
            <Header></Header>
            <Header2></Header2>

            <div class='row justify-content-center'>
              <div class='col-md-12'>
                <div class='cmn-container'>
                  <div class='contact-content'>
                    <div class='row'>
                      <div class='col-sm-6'>
                        <div className='row'>
                          <div className='col-12'>
                            <div class='text-center cmn-queries'>
                              <h2>
                                Innovative Cancer Care and Rehabilitation Pvt.
                                Ltd.
                                {/* <span>Prasenjit Basu</span> */}
                                <a href='mailto:info@icancare.in '>
                                  info@icancare.in
                                </a>
                                <a href='tel:+91 7838783268' class='cmn-link'>
                                  +91 7838783268 | 011 430 77695
                                </a>
                              </h2>
                            </div>
                          </div>
                          <div className='col-12'>
                            <div class='text-center cmn-queries address-sec'>
                              <p>
                                <span>Address</span>
                              </p>
                              <p>FF-02, Omaze Green Valley Plaza,</p>
                              <p>Sector 42, Faridabad,</p>
                              <p>Haryana -121001.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class='col-sm-6'>
                        <div className='row'>
                          <div className='col-12'>
                            <div class='text-center cmn-queries'>
                              <h2>
                                XR Central
                                {/* <span>Anand Mall</span> */}
                                <a href='mailto:social@gamingcentral.in'>
                                  social@gamingcentral.in
                                </a>
                                <a href='tel:+91 9810910263' class='cmn-link'>
                                  +91-9810910263 | +91-9999510667
                                </a>
                              </h2>
                            </div>
                          </div>
                          <div className='col-12'>
                            <div class='text-center cmn-queries address-sec'>
                              <p>
                                <span>Address</span>
                              </p>
                              <p>
                                Chamber C, 4th Floor, Cube 8 Coworking Space, MM
                                Tower, Sector 18,
                              </p>
                              <p>Udyog Vihar, Gurgaon, ,</p>
                              <p>Haryana 122002</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Check for user logged in */}
            {this.props.parentState.loggedIn ? null : (
              <Link to='/register' className='cmn-register-btn'>
                Register Now
              </Link>
            )}

            {/* Footer */}
            <Footer></Footer>
          </div>
        </div>
        <ParticlesBg type='color' bg={true} />
      </div>
    );
  }
}

export default Contact;
